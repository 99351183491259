import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import styles from './ProviderUserProfile.module.css';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import ListItem from '@mui/material/ListItem';
import { BACKEND_URL, CLIENT_ID } from '../../constants';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import {
  EGetProviderAction,
  MiscProviderType,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';

export const ProviderUserProfile: FC = () => {
  const { data: providers } = useGetProvidersQuery({
    client_id: CLIENT_ID,
    onlyActive: true,
    action: EGetProviderAction.add,
  });
  const [defaultProviders, setDefaultProviders] = useState(providers);
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const { clientId, userId = '' } = useParams<{ clientId: string; userId: string }>();
  const [getUsers] = useLazyGetUsersQuery();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === parseInt(userId, 10)));
    };
    start();
  }, [clientId, userId]);

  useEffect(() => {
    const filteredProviders = providers?.filter((provider) => {
      return provider.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setDefaultProviders(filteredProviders);
  }, [searchValue]);

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate(`/applications/user/${clientId}/${userId}`)}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['profile-provider']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Добавить способ входа
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-3C4567', 'text-14')}>
          Выберите способ входа, который хотите добавить в профиль{' '}
          <strong>{selectedUser?.user.nickname}</strong>:
        </Typography>
        {providers?.length && providers.length >= 10 && (
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ marginTop: 8 }}
            className={clsx(styles.search, 'custom')}
            variant="standard"
            placeholder="Поиск"
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
        )}
        <div className={styles.providers}>
          {(defaultProviders || providers)
            ?.filter(
              (provider) =>
                provider.type === MiscProviderType.EMAIL ||
                (provider.type === MiscProviderType.PHONE &&
                  !selectedUser?.user.phone_number),
            )
            .map((provider) => {
              return (
                <ListItem
                  key={provider.id}
                  className={styles.provider}
                  onClick={() => {
                    if (provider.type === 'EMAIL') {
                      navigate(`/applications/user/email/add/${clientId}/${userId}`);
                    } else if (provider.type === MiscProviderType.PHONE) {
                      navigate(`/applications/user/phone/add/${clientId}/${userId}`);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {provider.type === 'EMAIL' ? <MailIcon /> : !provider.avatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                </ListItem>
              );
            })}
        </div>
      </div>
    </div>
  );
};
