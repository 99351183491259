import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './EditProviders.module.css';
import { ReactComponent as AddIcon } from '../icons/Add.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as SearchIcon } from '../icons/Search.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDown.svg';
import { ReactComponent as CheckMarkIcon } from '../icons/CheckMark.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { ReactComponent as BasketIcon } from '../icons/Basket.svg';
import { ReactComponent as EditIcon } from '../icons/Edit.svg';
import { ReactComponent as CopyIcon } from '../icons/Copy.svg';
import { ReactComponent as EmptyListIcon } from '../icons/EmptyList.svg';
import { ReactComponent as EmptySearchIcon } from '../icons/EmptySearch.svg';
import { ReactComponent as Star } from '../icons/Star.svg';
import { ReactComponent as StarFilled } from '../icons/StarFilled.svg';
import { ChooseProvider } from './ChooseProvider';
import {
  useActivateProvidersMutation,
  useDeleteProviderMutation,
  useGetProvidersQuery,
  useLazyGetSecretQuery,
  useDeactivateProvidersMutation,
  MiscProviderType,
  TOauthProvider,
  TMiscProvider,
  OauthProviderType,
  EGetProviderAction,
  TEsiaParams,
} from '../redux/services/provider';
import { EditProvider } from './EditProvider';
import { BACKEND_URL, CLIENT_ID } from '../constants';
import { getProviderTitleByType } from '../helpers';
import {
  clientApi,
  useUpdateApplicationMutation,
  useGetApplicationByIdQuery,
} from '../redux/services/client';
import { EditLdapProvider } from './EditLdapProvider';
import { EditEthereumProvider } from './EditEthereumProvider';
import { CustomCheckbox } from './custom/CustomCheckbox';
import { CustomPopoverButton } from './custom/CustomPopoverButton';
import { EditSmsProvider } from './EditSmsProvider';
import { Edit1CProvider } from './Edit1CProvider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { EditIdmProvider } from './EditIdmProvider';
import { useParams } from 'react-router-dom-v5-compat';
import { EditKloudProvider } from './EditKloudProvider';
import { TProviderItem } from './applications/EditApplicationHeader';
import { ProviderScope, useGetRulesProvidersQuery } from '../redux/services/settings';
import { EditEsiaProvider } from './EditEsiaProvider';

type TEditProviders = {
  isOpen: boolean;
  close: () => void;
  clearSelectedProvider: () => void;
  selectedProvider?: TProviderItem;
  providerType?: ProviderScope;
};

export const EditProviders: FC<TEditProviders> = ({
  isOpen,
  close,
  clearSelectedProvider,
  selectedProvider,
  providerType = ProviderScope.internal,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const { clientId = '' } = useParams<{ clientId: string }>();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const { data: client, isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId || '' },
    {
      skip: !userId || !clientId,
    },
  );
  const selectedClient = client?.client;
  const [providers, setProviders] = useState<
    ((TOauthProvider | TMiscProvider) & { isPublic: boolean })[]
  >([]);
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEditLdapFormOpen, setIsEditLdapFormOpen] = useState(false);
  const [isEdit1CFormOpen, setIsEdit1CFormOpen] = useState(false);
  const [isEditIdmFormOpen, setIsEditIdmFormOpen] = useState(false);
  const [isEditEthereumFormOpen, setIsEditEthereumFormOpen] = useState(false);
  const [isEditSmsFormOpen, setIsEditSmsFormOpen] = useState(false);
  const [isEditKloudFormOpen, setIsEditKloudFormOpen] = useState(false);
  const [isEditEsiaFormOpen, setIsEditEsiaFormOpen] = useState(false);
  const typeVariants = ['Все', 'Публичные', 'Для приложения'];
  const [selectedTypeVariant, setSelectedTypeVariant] = useState(typeVariants[0]);
  const anchorPopover = useRef<HTMLButtonElement>(null);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    onlyActive: false,
    action: EGetProviderAction.change,
  });

  const isPublicProvider = (provider: TOauthProvider | TMiscProvider) =>
    provider.client_id === CLIENT_ID && clientId !== CLIENT_ID;

  const { data: rules = [] } = useGetRulesProvidersQuery();

  const filteredProviders: TProviderItem[] = [];
  providers.forEach((provider) => {
    const rule = rules.find((rule) => rule.type === provider.type);
    if (!rule) return false;
    if (rule && providerType) {
      switch (providerType) {
        case ProviderScope.trusted:
          if (!rule.allowedScopes.includes(providerType)) return false;
          break;
        case ProviderScope.internal:
          return false;
        default:
          break;
      }
    }
    if (selectedTypeVariant === typeVariants[1] && !provider.is_public) return false;
    if (selectedTypeVariant === typeVariants[2] && provider.is_public) return false;
    provider.name.toLowerCase().includes(searchValue.toLowerCase());
    filteredProviders.push({
      provider,
      rule,
    });
  });

  const dispatch = useDispatch();
  const [deleteProvider] = useDeleteProviderMutation();
  const [activateProvider] = useActivateProvidersMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [updateApplication, { isLoading: updateApplicationLoading }] =
    useUpdateApplicationMutation();
  const [getSecret] = useLazyGetSecretQuery();

  useEffect(() => {
    if (appProviders) {
      setProviders(
        appProviders.map((provider) => ({
          ...provider,
          isPublic: isPublicProvider(provider),
        })),
      );
    }
  }, [appProviders]);

  useEffect(() => {
    if (!isEditFormOpen) setProviderToEdit(null);
  }, [isEditFormOpen]);

  useEffect(() => {
    selectedProvider && createProviderSettings(selectedProvider.provider);
  }, [selectedProvider]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const createProviderSettings = async (provider: TOauthProvider | TMiscProvider) => {
    setProviderToEdit(provider);

    switch (provider.type) {
      case MiscProviderType.LDAP:
      case MiscProviderType.ALDPRO:
        return setIsEditLdapFormOpen(true);
      case MiscProviderType._1C:
        return setIsEdit1CFormOpen(true);
      case MiscProviderType.IDM:
        return setIsEditIdmFormOpen(true);
      case MiscProviderType.ETHEREUM:
        return setIsEditEthereumFormOpen(true);
      case MiscProviderType.SMS:
        return setIsEditSmsFormOpen(true);
      case MiscProviderType.KLOUD:
        return setIsEditKloudFormOpen(true);
      case MiscProviderType.ESIA:
        return setIsEditEsiaFormOpen(true);
      default:
        setIsEditFormOpen(true);
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    let clientSecret: string | undefined;
    if ((provider.type || '') in OauthProviderType) {
      const { data } = await getSecret({
        client_id: clientId,
        provider_id: provider?.id || '',
      });
      clientSecret = data;
    }

    await navigator.clipboard.writeText(
      JSON.stringify({
        ...provider,
        ...(clientSecret && { external_client_secret: clientSecret }),
      }),
    );
  };

  const handleDrawerClose = () => {
    clearSelectedProvider();
    close();
  };

  const handleActivateProvider = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [...ids, provider.id]);

    if (!provider.is_active) {
      await activateProvider({
        body: {
          providers: [
            {
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: [
            {
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    }

    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: clientId, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );

    setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== provider.id));
  };

  const handleActivateAllProviders = async () => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [
      ...ids,
      ...filteredProviders.map((providerItem) => providerItem.provider.id),
    ]);

    if (filteredProviders.some((providerItem) => !providerItem.provider.is_active)) {
      await activateProvider({
        body: {
          providers: filteredProviders?.map((providerItem) => {
            return {
              id: String(providerItem.provider.id),
              client_id: providerItem.provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: filteredProviders?.map((providerItem) => {
            return {
              id: String(providerItem.provider.id),
              client_id: providerItem.provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });

      dispatch(
        clientApi.endpoints.getApplicationById.initiate(
          { client_id: clientId, user_id: userId },
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      );
    }

    setFetchingProviderIds(
      fetchingProviderIds.filter((id) =>
        filteredProviders.find((provider) => provider.provider.id !== id),
      ),
    );
  };

  const handleChangeRequiredProviders = async (
    provider: (TOauthProvider | TMiscProvider) & {
      isPublic: boolean;
    },
  ) => {
    if (!userId || updateApplicationLoading || getApplicationFetching || !selectedClient) return;
    const provider_id =
      ((provider.type || '') in OauthProviderType ? 'oauth_' : 'misc_') + provider.id;
    await updateApplication({
      currentClientId: selectedClient.client_id,
      params: {
        required_providers_ids: selectedClient.required_providers_ids.includes(provider_id)
          ? selectedClient.required_providers_ids.filter((id) => id !== provider_id)
          : [...selectedClient.required_providers_ids, provider_id],
        grant_types: selectedClient?.grant_types,
        registration_access_token: selectedClient?.registration_access_token?.jti,
        client_id: selectedClient?.client_id,
        redirect_uris: selectedClient?.redirect_uris,
        post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris,
        require_signed_request_object: selectedClient?.require_signed_request_object,
        request_uris: selectedClient?.request_uris,
        id_token_signed_response_alg: selectedClient?.id_token_signed_response_alg,
        response_types: selectedClient?.response_types,
        introspection_endpoint_auth_method: selectedClient?.introspection_endpoint_auth_method,
        require_auth_time: selectedClient?.require_auth_time,
        revocation_endpoint_auth_method: selectedClient?.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient?.token_endpoint_auth_method,
        subject_type: selectedClient?.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <Drawer
        onClose={() => handleDrawerClose()}
        open={isOpen}
        anchor="right"
        variant="temporary"
        classes={{ paper: styles['drawer-paper'] }}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
              {providerType === ProviderScope.trusted
                ? 'Настроить провайдеры'
                : 'Настроить способы входа'}
            </Typography>
            <Button
              onClick={() => setIsCreateFormOpen(true)}
              variant="custom"
              className={clsx('text-14', 'color-0B1641', styles['create-button'])}
              color="secondary"
              startIcon={<AddIcon />}
            >
              Создать
            </Button>
            <IconButton onClick={handleDrawerClose} className={styles['close-button']}>
              <CloseIcon />
            </IconButton>
          </div>
          {providerType === ProviderScope.trusted ? (
            <div>
              <Typography className={clsx('text-15', 'color-858BA0', styles['input-description'])}>
                Создайте доверенный провайдер и добавьте его в приложение как способ входа.
              </Typography>
              <Typography className={clsx('text-15', 'color-858BA0', styles['input-description'])}>
                Добавить можно только один доверенный провайдер.
              </Typography>
            </div>
          ) : (
            <Typography className={clsx('text-15', 'color-858BA0', styles['input-description'])}>
              Выберите способы входа которые хотите добавить в приложение:
            </Typography>
          )}
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={clsx(styles.search, 'custom')}
            fullWidth
            variant="standard"
            placeholder="Поиск"
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
          {providerType !== ProviderScope.trusted && (
            <div className={styles['types-wrapper']}>
              <CustomCheckbox
                onClick={handleActivateAllProviders}
                disabled={!!fetchingProviderIds.length}
                className={styles.checkbox}
                checked={
                  filteredProviders.every((provider) => provider.provider.is_active) &&
                  !!filteredProviders.length
                }
              />
              <Button
                ref={anchorPopover}
                className={styles['button-type']}
                onClick={() => setPopoverOpen(true)}
                endIcon={<ArrowDownIcon fill="#B6BAC6" />}
              >
                <Typography className={clsx('text-14', 'color-858BA0')}>Тип:</Typography>
                <Typography
                  className={clsx('text-14', 'color-0B1641', styles['button-type-variant-text'])}
                >
                  {selectedTypeVariant}
                </Typography>
              </Button>
            </div>
          )}
          <Popover
            classes={{ paper: styles.popover }}
            open={popoverOpen}
            anchorEl={anchorPopover.current}
            onClose={() => setPopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {typeVariants.map((variant) => (
              <CustomPopoverButton
                key={variant}
                onClick={() => {
                  setSelectedTypeVariant(variant);
                  setPopoverOpen(false);
                }}
                startIcon={
                  <CheckMarkIcon
                    className={clsx(styles['popover-button-icon'], {
                      [styles['hidden-button-icon']]: variant !== selectedTypeVariant,
                    })}
                  />
                }
              >
                {variant}
              </CustomPopoverButton>
            ))}
          </Popover>
          <div style={{ overflow: 'auto', height: 'calc(100vh - 294px)' }}>
            {!providers.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptyListIcon />
                <Typography style={{ marginBottom: 8 }} className={clsx('text-17', 'color-858BA0')}>
                  Список пуст
                </Typography>
                <Typography
                  className={clsx('text-14')}
                  style={{ marginBottom: 16, color: '#B6BAC6' }}
                >
                  Добавьте свои способы входа
                </Typography>
                <Button
                  onClick={() => setIsCreateFormOpen(true)}
                  variant="custom"
                  className={clsx('text-14', 'color-0B1641')}
                  color="secondary"
                  startIcon={<AddIcon />}
                >
                  Создать
                </Button>
              </div>
            )}
            {!!providers.length && !filteredProviders.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptySearchIcon />
                <Typography style={{ marginBottom: 8 }} className={clsx('text-17', 'color-858BA0')}>
                  По вашему запросу ничего не найдено
                </Typography>
              </div>
            )}
            {filteredProviders
              .sort((a, b) => {
                if (a.provider.is_active !== b.provider.is_active)
                  return !a.provider.is_active ? 1 : -1;
                return a.provider.client_id === CLIENT_ID ? 1 : -1;
              })
              .map((provider) => {
                const required = selectedClient?.required_providers_ids.includes(
                  ((provider.provider.type || '') in OauthProviderType ? 'oauth_' : 'misc_') +
                    provider.provider.id,
                );
                return (
                  <div
                    className={clsx(styles.provider, {
                      [styles['checked-provider']]: provider.provider.is_active,
                    })}
                    key={provider.provider.id + provider.provider.type}
                  >
                    {providerType !== ProviderScope.trusted && (
                      <CustomCheckbox
                        onClick={() => {
                          handleActivateProvider(provider.provider);
                        }}
                        checked={provider.provider.is_active}
                        className={styles['provider-checkbox']}
                        disabled={
                          !!fetchingProviderIds.find((id) => id === provider.provider.id) ||
                          (clientId === CLIENT_ID &&
                            provider.provider.type === MiscProviderType.CREDENTIALS)
                        }
                      />
                    )}
                    <div
                      style={{
                        backgroundImage: `url(${BACKEND_URL + '/' + provider.provider.avatar})`,
                      }}
                      className={styles['provider-icon-wrapper']}
                    >
                      {!provider.provider.avatar && (
                        <IdIcon fill="#CED0D9" className={styles['id-icon']} />
                      )}
                    </div>
                    <div className={styles['provider-name-wrapper']}>
                      <Typography
                        className={clsx('text-14', 'color-0B1641', styles['provider-name'])}
                      >
                        {provider.provider.name}
                      </Typography>
                      <Typography className={clsx('text-12', 'color-858BA0')}>
                        {getProviderTitleByType(provider.provider.type)}
                      </Typography>
                    </div>
                    {providerType &&
                      providerType !== ProviderScope.trusted &&
                      provider.rule.requireable && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              <Typography className="text-17">
                                Сделать {required ? 'необязательным' : 'обязательным'}
                              </Typography>
                              <Typography className="text-12">
                                Для входа в приложение {required ? 'не' : ''} требуется наличие
                                способа входа в профиле пользователя
                              </Typography>
                            </div>
                          }
                          classes={{
                            tooltip: styles['input-tooltip'],
                            arrow: styles['input-tooltip-arrow'],
                          }}
                        >
                          <IconButton
                            disabled={!provider.provider.is_active}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChangeRequiredProviders({
                                ...provider.provider,
                                isPublic: isPublicProvider(provider.provider),
                              });
                            }}
                            className={styles['required-provider-button']}
                          >
                            {required ? (
                              <StarFilled
                                fill="rgb(182, 186, 198)"
                                width={20}
                                height={20}
                                className={styles['icon-button']}
                              />
                            ) : (
                              <Star
                                fill="rgb(182, 186, 198)"
                                width={20}
                                height={20}
                                className={styles['icon-button']}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    {!isPublicProvider(provider.provider) && provider.rule.deletable && (
                      <div className={styles['provider-buttons']}>
                        {providerType && provider.rule.allowedScopes.includes(providerType) && (
                          <Tooltip
                            arrow
                            title={'Удалить'}
                            classes={{
                              tooltip: styles['input-tooltip'],
                              arrow: styles['input-tooltip-arrow'],
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setProviderToDelete(provider.provider);
                                setDeleteModalOpen(true);
                              }}
                              className={styles['provider-button']}
                              disabled={
                                !!fetchingProviderIds.find((id) => id === provider.provider.id) ||
                                providersFetching
                              }
                            >
                              <BasketIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {provider.rule.editable &&
                          providerType &&
                          provider.rule.allowedScopes.includes(providerType) && (
                            <>
                              <Tooltip
                                arrow
                                onClick={() => {
                                  createProviderSettings(provider.provider);
                                }}
                                title={'Редактировать'}
                                classes={{
                                  tooltip: styles['input-tooltip'],
                                  arrow: styles['input-tooltip-arrow'],
                                }}
                              >
                                <IconButton className={styles['provider-button']}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                placement="bottom-end"
                                arrow
                                title={'Копировать настройки'}
                                classes={{
                                  tooltip: styles['input-tooltip'],
                                  arrow: styles['input-tooltip-arrow'],
                                }}
                              >
                                <IconButton
                                  onClick={() => handleCopyProvider(provider.provider)}
                                  className={styles['provider-button']}
                                >
                                  <CopyIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </Drawer>
      <ChooseProvider
        isOpen={isCreateFormOpen}
        close={() => setIsCreateFormOpen(false)}
        providerType={providerType || ProviderScope.trusted}
        providersList={filteredProviders}
      />
      <EditProvider
        providerToEdit={providerToEdit as TOauthProvider}
        isOpen={isEditFormOpen}
        close={() => {
          setIsEditFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditLdapProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditLdapFormOpen}
        close={() => {
          setIsEditLdapFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <Edit1CProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEdit1CFormOpen}
        close={() => {
          setIsEdit1CFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditIdmProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditIdmFormOpen}
        close={() => {
          setIsEditIdmFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditEsiaProvider
        providerToEdit={
          providerToEdit as TMiscProvider & {
            params: TEsiaParams;
            provider_id: string;
            isPublic: boolean;
            avatar?: File | null;
          }
        }
        isOpen={isEditEsiaFormOpen}
        close={() => {
          setIsEditEsiaFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditEthereumProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditEthereumFormOpen}
        close={() => {
          setIsEditEthereumFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditSmsProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditSmsFormOpen}
        close={() => {
          setIsEditSmsFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <EditKloudProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditKloudFormOpen}
        close={() => {
          setIsEditKloudFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
        scope={providerType}
      />
      <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
        <div className={styles['delete-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Удалить способ входа
            </Typography>
            <IconButton onClick={closeDeleteModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Способ входа будет удален. Если способ входа используется в приложении, пользователи не
            смогут войти в него, используя данный способ входа.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeDeleteModal}>
              Отмена
            </Button>
            <Button
              disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
              onClick={handleDeleteProvider}
              variant="custom"
              style={{ marginLeft: 24, background: '#FC545C' }}
            >
              Удалить
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
