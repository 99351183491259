import Drawer from '@mui/material/Drawer';
import styles from './EditRuleValidations.module.css';
import React, { FC, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import clsx from 'clsx';
import {
  TRuleValidation,
  useGetRuleValidationsByFieldNameQuery,
  useGetRuleValidationsQuery,
} from '../../redux/services/settings';
import Button from '@mui/material/Button';
import { EditRuleValidation } from './EditRuleValidation';
import { RuleValidation } from './RuleValidation';

export type TEditRuleValidations = {
  isOpen: boolean;
  field_name: string;
  close: () => void;
};

export const EditRuleValidations: FC<TEditRuleValidations> = ({ isOpen, field_name, close }) => {
  const handleDrawerClose = () => {
    close();
  };

  const [ruleValidationModalOpen, setRuleValidationModalOpen] = useState(false);
  const [ruleValidationField, setRuleValidationField] = useState<TRuleValidation | undefined>(
    undefined,
  );

  const { data: ruleValidations = [] } = useGetRuleValidationsQuery();
  const { data: ruleValidationsField = [] } = useGetRuleValidationsByFieldNameQuery(field_name);

  const listTitleRuleValidations = ruleValidations
    .map((ruleValidation) => (
      <RuleValidation
        key={ruleValidation.id}
        profile={ruleValidation}
        onEdit={() => {
          setRuleValidationField(ruleValidation);
          setRuleValidationModalOpen(true);
        }}
        checkBox={!!ruleValidationsField.find((f) => f.id === ruleValidation.id)}
        field_name={field_name}
      />
    ))
    .reverse()
    .sort((a, b) => {
      if (a.props.checkBox && !b.props.checkBox) {
        return -1;
      }
      if (!a.props.checkBox && b.props.checkBox) {
        return 1;
      }
      return 0;
    });

  const headerText = `Настройка правил валидации для '${field_name}'`;

  return (
    <Drawer
      onClose={() => handleDrawerClose()}
      open={isOpen}
      anchor="right"
      variant="temporary"
      classes={{ paper: styles['drawer-paper'] }}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
            {headerText}
          </Typography>
          <IconButton onClick={handleDrawerClose} className={styles['close-button']}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography className={styles.description}>
          Выберите правила валидации, которые хотите применить для поля:
        </Typography>
        <Button
          variant="custom2"
          className={styles['add-button']}
          startIcon={<AddIcon className={styles['add-icon']} />}
          onClick={() => setRuleValidationModalOpen(true)}
        >
          Добавить
        </Button>
        {listTitleRuleValidations}
      </div>
      <EditRuleValidation
        isOpen={ruleValidationModalOpen}
        close={() => setRuleValidationModalOpen(false)}
        clearRuleValidation={() => setRuleValidationField(undefined)}
        selectedRuleValidation={ruleValidationField}
      />
    </Drawer>
  );
};
