import React, { useState, FC } from 'react';
import clsx from 'clsx';
import styles from './BindAccount.module.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useBindAccountMutation } from '../../redux/services/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  useChangeLdapPasswordMutation,
  useLazyCheckLdapPasswordChangeRequireQuery,
} from '../../redux/services/ldap';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';

export const BindLDAPAccount: FC = () => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [bindError, setBindError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [bindLdapAccount, { isLoading: bindLdapAccountLoading }] = useBindAccountMutation();
  const [checkLdapPasswordChangeRequire, { isLoading: checkPasswordChangeRequireLoading }] =
    useLazyCheckLdapPasswordChangeRequireQuery();
  const [changeLdapPassword] = useChangeLdapPasswordMutation();

  const handleAddLdapAccount = async () => {
    try {
      setPasswordError('');
      setUsernameError('');
      if (!username) return setUsernameError('Введите логин');
      if (!password) return setPasswordError('Введите пароль');

      const needToChangePassword = await checkLdapPasswordChangeRequire({
        username,
        password,
        provider_id,
      }).unwrap();

      if (needToChangePassword) return setPasswordChangeRequired(true);

      if (userId) {
        const bindLdapAccountResponse = await bindLdapAccount({
          userId,
          provider_id,
          username,
          password,
          rebind: !!bindError,
        }).unwrap();

        if (!bindLdapAccountResponse.success)
          return setBindError(`Аккаунт LDAP уже привязан к ${bindLdapAccountResponse.nickname}`);
        navigate('/profile');
      }
    } catch (e) {
      console.log('handleAddLdapAccount error:', e);
    }
  };

  const changePasswordClick = async () => {
    try {
      if (!newPassword) return setNewPassword('Введите новый пароль');
      if (newPassword !== repeatNewPassword) return setNewPasswordError('Пароли не совпадают');
      setNewPasswordError('');

      await changeLdapPassword({
        username,
        current_password: password,
        new_password: newPassword,
        provider_id,
      }).unwrap();

      if (userId) {
        await bindLdapAccount({ userId, provider_id, username, password }).unwrap();
      }
    } catch (e) {
      console.log('changePasswordClick error:', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Добавить аккаунт LDAP
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Логин
        </Typography>
        <TextField
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={clsx('custom', styles.textfield)}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
          disabled={passwordChangeRequired}
          error={!!usernameError}
          helperText={usernameError}
        />
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Пароль
        </Typography>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={clsx('custom', styles.textfield)}
          type="password"
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
          disabled={passwordChangeRequired}
          error={!!passwordError || !!bindError}
          helperText={passwordError || bindError}
        />
        {passwordChangeRequired && (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Новый пароль
            </Typography>
            <TextField
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={clsx('custom', styles.textfield)}
              type="password"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
            />
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Подтвердите пароль
            </Typography>
            <TextField
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              className={clsx('custom', styles.textfield)}
              type="password"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
            <Typography className={clsx('text-14', 'color-858BA0')}>
              Необходимо сменить пароль
            </Typography>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={bindLdapAccountLoading || checkPasswordChangeRequireLoading}
            onClick={!passwordChangeRequired ? handleAddLdapAccount : changePasswordClick}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            {(bindLdapAccountLoading || checkPasswordChangeRequireLoading) && (
              <CircularProgress size={24} style={{ color: 'white' }} />
            )}
            {!(bindLdapAccountLoading || checkPasswordChangeRequireLoading) &&
              (bindError ? 'Перепривязать' : 'Добавить')}
          </Button>
        </div>
      </div>
    </div>
  );
};
