import React, { useState, useEffect, KeyboardEvent, FC } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useAddPhoneMutation } from '../../redux/services/user';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { NumericFormatCustom } from '../NumericFormatCustom';
import { useCallPhoneMutation, useLazyCheckPhoneExistsQuery } from '../../redux/services/phone';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';

const mapStateToProps = (state: RootState) => ({
  currentPhone: state.user.userProfile.phone_number,
});

type TAddPhoneComponent = {
  currentPhone?: TUserProfile['phone_number'];
};

export const AddPhoneComponent: FC<TAddPhoneComponent> = ({ currentPhone }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isPhoneCalled, setIsPhoneCalled] = useState(false);
  const [[minute, second], setTime] = useState([1, 30]);
  const [codeType, setCodeType] = useState('');
  const [codeLength, setCodeLength] = useState(0);
  const [addPhone] = useAddPhoneMutation();
  const navigate = useNavigate();
  const [callPhone] = useCallPhoneMutation();
  const [checkPhoneExists] = useLazyCheckPhoneExistsQuery();
  const { action = '' } = useParams<{ action: string }>();

  // код может быть длинной от 4 до 8 символов
  const dynamicText = codeLength === 4 ? `4 последние цифры` : `${codeLength} последних цифр`;

  useEffect(() => {
    if (action === 'change' && currentPhone) {
      const newValue = currentPhone.slice(1);
      setPhoneNumber(newValue);
    } else setPhoneNumber('');
  }, [currentPhone, action]);

  useEffect(() => {
    setTime([1, 30]);
  }, [isPhoneCalled]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const showMessage = (codeType: string) => {
    switch (codeType) {
      case 'number':
        return `На указанный номер поступит бесплатный звонок. Укажите ${dynamicText} входящего номера`;
      case 'voice':
        return 'На указанный номер поступит бесплатный звонок c голосовым сообщением с кодом авторизации';
      case 'sms':
        return 'На указанный номер отправлено СМС сообщение с кодом авторизации';
    }
  };

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const getCodeError = () => {
    if (!code) return 'Укажите код';
  };

  const codeInput = () => {
    setIsPhoneCalled((isPhoneCalled) => !isPhoneCalled);
    setCodeError('');
    setCode('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
  };

  const handleCallPhone = async () => {
    if (getPhoneNumberError()) return;
    try {
      const checkPhoneResponse = await checkPhoneExists(phoneNumber).unwrap();
      if (!checkPhoneResponse?.isAvailable) {
        return setPhoneNumberError('Номер телефона используется другим пользователем');
      }

      const result = await callPhone(phoneNumber).unwrap();

      if (result?.success) {
        setIsPhoneCalled(true);
        setTime([1, 30]);
        setCodeType(result.code_type);
        if (result?.code_length) setCodeLength(result.code_length);
      } else if (result?.message) {
        // message в result появлется в случае ошибки в запросе
        setPhoneNumberError(result.message);
      }
    } catch (e) {
      console.log('handleCallPhone ', e);
    }
  };

  const handleCheckPhoneCode = async () => {
    if (getCodeError()) return;
    try {
      await addPhone({
        code,
        phone_number: '+7' + phoneNumber,
      }).unwrap();

      navigate('/profile/edit');
    } catch (e) {
      console.log('handleCheckPhoneCode', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile/edit')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          {action === 'add' ? 'Добавить телефон' : 'Изменить номер телефона'}
        </Typography>
        {!isPhoneCalled ? (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Укажите номер телефона
            </Typography>
            <TextField
              value={phoneNumber}
              onChange={handleChange}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const phoneNumberErr = getPhoneNumberError();
                if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
              }}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  const phoneNumberErr = getPhoneNumberError();
                  if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
                  handleCallPhone();
                }
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!phoneNumberError}
              helperText={phoneNumberError ? phoneNumberError : ''}
              fullWidth
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumericFormatCustom as any,
              }}
              variant="standard"
              autoFocus
            />
            <Typography className={clsx('text-14', 'color-858BA0')}>
              На указаный номер поступит бесплатный звонок
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              {showMessage(codeType)}
            </Typography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (isPhoneCalled) {
                    return handleCheckPhoneCode();
                  }
                }
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
              autoFocus
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <Typography className={clsx('text-14', 'color-858BA0')}>
                  Позвонить повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </Typography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={handleCallPhone}
                >
                  Позвонить повторно
                </Button>
              )}
              <Button
                onClick={codeInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить номер
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile/edit')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isPhoneCalled) {
                return handleCheckPhoneCode();
              }
              handleCallPhone();
            }}
          >
            {isPhoneCalled ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const AddPhone = connect(mapStateToProps)(AddPhoneComponent);
