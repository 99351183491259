import React, { useState, FC } from 'react';
import clsx from 'clsx';
import styles from './BindAccount.module.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useBindAccountMutation } from '../../redux/services/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';

export const BindIdmAccount: FC = () => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [bindError, setBindError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [bindIdmAccount, { isLoading: bindIdmAccountLoading }] = useBindAccountMutation();

  const handleAddIdmAccount = async () => {
    try {
      setPasswordError('');
      setUsernameError('');
      if (!username) return setUsernameError('Введите логин');
      if (!password) return setPasswordError('Введите пароль');

      if (userId) {
        const bindIdmAccountResponse = await bindIdmAccount({
          userId,
          provider_id,
          username,
          password,
          rebind: !!bindError,
        }).unwrap();

        if (!bindIdmAccountResponse.success)
          return setBindError(`Аккаунт IDM уже привязан к ${bindIdmAccountResponse.nickname}`);
        navigate('/profile');
      }
    } catch (e) {
      console.log(handleAddIdmAccount.name + ' error:', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Добавить аккаунт IDM
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Логин
        </Typography>
        <TextField
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={clsx('custom', styles.textfield)}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
          error={!!usernameError}
          helperText={usernameError}
        />
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Пароль
        </Typography>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={clsx('custom', styles.textfield)}
          type="password"
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
          error={!!passwordError || !!bindError}
          helperText={passwordError || bindError}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={bindIdmAccountLoading}
            onClick={handleAddIdmAccount}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            {bindIdmAccountLoading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              !bindIdmAccountLoading && (bindError ? 'Перепривязать' : 'Добавить')
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
