import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from './EditProfile.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserProfile, TCustomFields, TUserProfile, TUserSlice } from '../../redux/userSlice';
import { checkIdentifier } from '../../requests/user';
import { getUserProfile } from '../../requests/oidc';
import { convertUserProfile, editProfileSchema, getMonthsByYear, isDateError } from '../../helpers';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { setNotice } from '../../redux/noticesSlice';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProfileFields } from './ProfileFields';
import {
  useGetProfileFieldsQuery,
  useGetRulesQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { useUpdateUserMutation } from '../../redux/services/user';

export type EditProfileInputs = {
  given_name: string;
  picture: File | string | null;
  nickname: string;
  family_name: string;
  login: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  userId: state.user.userProfile.id,
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TEditProfileComponent = {
  userProfile: TUserSlice['userProfile'];
  userId?: string;
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const EditProfileComponent: FC<TEditProfileComponent> = ({
  userProfile,
  userId,
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const { data: rules, refetch: refetchRules } = useGetRulesQuery();
  useEffect(() => {
    refetchRules();
  }, []);

  const methods = useForm<EditProfileInputs>({
    resolver: yupResolver(
      editProfileSchema(rules ? rules.filter((rule) => rule.field_name !== 'password') : []),
    ),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;

  const watchBirthYear = watch('birthYear');

  const navigate = useNavigate();

  const { data: dataSettings } = useGetSettingsQuery();
  const { data: profileFields } = useGetProfileFieldsQuery();
  const [updateUser] = useUpdateUserMutation();

  const dispatch = useDispatch();
  const months = getMonthsByYear(watchBirthYear);

  useEffect(() => {
    const customFields = profileFields?.filter((key) => key.type === 'custom' && key.active) || [];

    (
      [
        'nickname',
        'given_name',
        'family_name',
        'login',
        'email',
        'phone_number',
        ...customFields.map((c) => c.field),
      ] as Array<
        keyof Omit<EditProfileInputs, 'birthDay' | 'birthMonth' | 'birthYear' | 'picture'> &
          TCustomFields
      >
    ).forEach((field) => {
      if (customFields.find((c) => c.field === field)) {
        setValue(
          field,
          userProfile.custom_fields ? userProfile.custom_fields[field as keyof TUserProfile] : '',
        );
      }
      if (userProfile[field as keyof TUserProfile]) {
        setValue(field, userProfile[field as keyof TUserProfile] || '');
      }
    });
    if (userProfile.birthdate) {
      const date = new Date(userProfile.birthdate);
      setValue('birthYear', String(date.getFullYear()));
      setValue('birthMonth', months[date.getMonth()]?.name);
      setValue('birthDay', String(date.getDate()));
    }
  }, [userProfile]);

  const onSubmit: SubmitHandler<EditProfileInputs & TCustomFields> = async (data) => {
    const month = months.findIndex(({ name }) => name === data.birthMonth);
    const birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));
    if (!userId) return;
    if (isDateError(birthdate, dataSettings)) return;

    const customFields = profileFields?.filter((key) => key.type === 'custom' && key.active) || [];
    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (acc: Partial<TUserProfile>, field) => {
        if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
          return acc;
        }

        if (field === 'picture') {
          acc.picture = data.picture as any;
          return acc;
        }

        if (customFields.find((cf) => cf.field === field)) {
          acc.custom_fields = acc.custom_fields || {};
          acc.custom_fields[field] = data[field];
          return acc;
        }

        acc[field] = data[field];

        return acc;
      },
      {},
    );

    if ('birthDay' in dirtyFields || 'birthYear' in dirtyFields || 'birthMonth' in dirtyFields)
      payload.birthdate = (birthdate as unknown as Date).toISOString();

    if ('login' in dirtyFields && userProfile.login !== data.login) {
      const isAvailable = await checkIdentifier(data.login);
      if (isAvailable) {
        setError('login', {
          type: 'api',
          message: 'Уже существует',
        });
        return;
      }
    }
    try {
      await updateUser({ data: payload, userId }).unwrap();
      const profile = await getUserProfile();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);
        dispatch(setUserProfile(convertedProfile));
      }
      navigate('/profile');
      dispatch(setIsChangesUnsaved(false));
    } catch (e: any) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: e.message,
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  const [agreeModal, setAgreeModal] = React.useState(false);
  const [dataList, setDataList] = React.useState<JSX.Element[] | undefined>(undefined);
  const checkRequiredFields = async () => {
    const result =
      profileFields?.filter(
        (rule) => rule.required && rule.field !== 'password' && watch(rule.field as any) === '',
      ) || [];
    if (result.length > 0) {
      setDataList(
        result.map((rule) => {
          return <div key={rule.title}>{rule.title}</div>;
        }),
      );
      setAgreeModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleModalClose = () => {
    setAgreeModal(false);
  };

  const handleModalContinue = () => {
    setAgreeModal(false);
    handleSubmit(onSubmit)();
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={
          isChangesUnsaved
            ? () => {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath('/profile'));
              }
            : () => {
                dispatch(setComponentPath('/profile'));
                navigate(componentPath);
              }
        }
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkRequiredFields();
          }}
          className={styles['create-client-form']}
        >
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Редактировать профиль
          </Typography>
          <ProfileFields userProfile={userProfile} />
        </form>
      </FormProvider>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={agreeModal}
        onClose={handleModalClose}
      >
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            У Вас есть незаполненные обязательные поля, необходимые для дальнейшей работы:
            {dataList}
          </Typography>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Продолжить сохранение?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={handleModalClose}>
              Отмена
            </Button>
            <Button onClick={handleModalContinue} variant="custom" style={{ marginLeft: 24 }}>
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={isModalOpen} onClose={() => dispatch(setIsModalOpen(false))}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="custom"
              color="secondary"
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginRight: 24 }}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                relocation();
              }}
              variant="custom"
              color="primary"
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const EditProfile = connect(mapStateToProps)(EditProfileComponent);
