import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  MiscProviderType,
  T1CParams,
  TEditProvider,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../redux/services/provider';

import { isObjectEmpty } from '../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import Switch from '@mui/material/Switch';
import { ProviderColor, TProviderColors } from './ProviderColor';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';

const _1CParams = ['url', 'admin_login', 'admin_password', 'mapping', 'secret'];

export type Edit1CProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  admin_login: string;
  admin_password: string;
  secret: string;
  provider_colors: TProviderColors;
  auto_registration: boolean;
  mapping: string;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  provider_title: string;
  show_provider_avatar: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера 1C не может превышать 255 символов')
    .url('Введите корректный адрес'),
  admin_login: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Логин не может превышать 255 символов'),
  admin_password: yup.string().max(255, 'Пароль не может превышать 255 символов'),
  secret: yup.string().required('Обязательное поле'),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  disable_password_reset: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  provider_colors: yup.object({
    button_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
    font_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
  }),
});

export const Edit1CProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<Edit1CProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, update1CResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const showAvatar = useWatch({ control, name: 'show_provider_avatar' });

  useEffect(() => {
    if (update1CResult.isSuccess) close();
  }, [update1CResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<TMiscProvider & { provider_id: string; isPublic: boolean }>,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              provider_id: string;
              isPublic: boolean;
              client_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, params, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.isPublic;
      delete restInputs.client_id;

      if (type !== MiscProviderType._1C) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (params) {
          (Object.keys(params) as Array<keyof T1CParams>).forEach((field) => {
            setValue(field, (params as T1CParams)?.[field] || '', { shouldDirty: !provider });
          });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<Edit1CProviderInputs, keyof T1CParams | 'avatar'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public' ||
              field === 'disable_password_reset'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }

            if (field === 'provider_colors') {
              return setValue(
                field,
                { ...(restInputs?.[field] as TProviderColors) },
                { shouldDirty: !provider },
              );
            }

            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<Edit1CProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'params'> & {
              avatar?: File | null;
              provider_id: string;
            } & T1CParams
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public' ||
            field === 'show_provider_avatar' ||
            field === 'disable_password_reset'
          ) {
            acc[field] = data[field];
            return acc;
          }

          if (field === 'provider_colors') {
            acc[field] = data[field];
            return acc;
          }

          if (_1CParams.includes(field)) {
            (_1CParams as Array<keyof T1CParams>).forEach((param) => {
              acc[param] = data[param];
            });
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType._1C,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать способ входа 1C
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес сервера 1C
            </Typography>
            <TextField
              {...register('url', {
                required: true,
                onBlur: (event: FocusEvent<HTMLInputElement>) => {
                  setValue('url', event.target.value.trim());
                },
                onChange: () => {
                  if (errors.url) clearErrors('url');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.url}
              helperText={errors.url ? errors.url.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Адрес публикации информационной базы
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Логин 1C
            </Typography>
            <TextField
              {...register('admin_login', {
                required: true,
                onChange: () => {
                  if (errors.admin_login) clearErrors('admin_login');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.admin_login}
              helperText={errors.admin_login ? errors.admin_login.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Логин для доступа к 1C
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Пароль 1C
            </Typography>
            <PasswordTextfield
              {...register('admin_password', {
                onChange: () => {
                  if (errors.admin_password) clearErrors('admin_password');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.admin_password}
              helperText={errors.admin_password ? errors.admin_password.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Пароль для доступа к 1C
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Secret
            </Typography>
            <PasswordTextfield
              {...register('secret', {
                required: true,
                onChange: () => {
                  if (errors.secret) clearErrors('secret');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.secret}
              helperText={errors.secret ? errors.secret.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Secret
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Сопоставление атрибутов 1C
            </Typography>
            <TextField
              {...register('mapping', {
                required: true,
                onChange: () => {
                  if (errors.mapping) clearErrors('mapping');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.mapping}
              helperText={errors.mapping ? errors.mapping.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Сопоставление атрибутов в формате: sub:dn, given_name:givenName, family_name:sn,
              email:mail, login: userPrincipalName (маппинг на поле логин работает только при
              авторегистрации)
            </Typography>
            <ProviderFooter type={MiscProviderType._1C} clientId={clientId} />
            <div className={styles['switch-wrapper']}>
              <Typography className={clsx('text-14', 'color-0B1641')}>
                Показывать логотип 1C в виджете
              </Typography>
              <Switch
                checked={showAvatar}
                {...register('show_provider_avatar', {
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    setValue('show_provider_avatar', event.target.checked, { shouldDirty: true });
                  },
                })}
              />
            </div>
            <ProviderColor avatarSrc={avatarSrc} providerName={providerToEdit?.name} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={update1CResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для 1C-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                closeClipboardModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
