import React, { FC, useState } from 'react';
import styles from './RuleValidation.module.css';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { ReactComponent as Edit } from '../../icons/Edit.svg';
import { ReactComponent as Basket } from '../../icons/Basket.svg';
import {
  TRuleValidation,
  useAddRuleValidationToRuleMutation,
  useDeleteRuleValidationMutation,
  useRemoveRuleValidationFromRuleMutation,
} from '../../redux/services/settings';
import { Checkbox } from '@mui/material';

type TRuleValidationField = {
  profile: TRuleValidation;
  onEdit: () => void;
  checkBox: boolean;
  field_name: string;
};

export const RuleValidation: FC<TRuleValidationField> = ({
  profile,
  onEdit,
  checkBox,
  field_name,
}) => {
  const [deleteRuleValidation] = useDeleteRuleValidationMutation();
  const [addRuleValidationToRule] = useAddRuleValidationToRuleMutation();
  const [removeRuleValidationFromRule] = useRemoveRuleValidationFromRuleMutation();
  const [saveCheckBox, setCheckBox] = useState<boolean>(checkBox);

  const handleDelete = async () => {
    await deleteRuleValidation(profile.id);
  };

  const handleCheckBox = async () => {
    setCheckBox(!saveCheckBox);
    if (!saveCheckBox) {
      addRuleValidationToRule({ field_name, id: profile.id });
    } else {
      removeRuleValidationFromRule({ field_name, id: profile.id });
    }
  };

  return (
    <ListItem className={`${styles['rule-item']} ${!profile.active ? styles['inactive'] : ''}`}>
      <div className={styles['rule-content']}>
        <div className={styles['rule-head']}>
          <Checkbox checked={saveCheckBox} onClick={handleCheckBox} />
          <Typography className={styles['rule-title']}>{profile.title}</Typography>
        </div>
        <div className={styles['rule-icons']}>
          <Edit className={styles['rule-icon']} onClick={onEdit} />
          <Basket className={styles['rule-icon']} onClick={handleDelete} />
        </div>
      </div>
    </ListItem>
  );
};
