import { TWidgetColors } from './EditApplication';
import * as yup from 'yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import { ReactComponent as CopyIcon } from '../../icons/Copy.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as Star } from '../../icons/Star.svg';
import { ReactComponent as StarFilled } from '../../icons/StarFilled.svg';
import IconButton from '@mui/material/IconButton';
import { IconWrapper } from '../IconWrapper';
import { getProviderTitleByType } from '../../helpers';
import Skeleton from '@mui/material/Skeleton';
import React, { FC, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { SubmitHandler, useForm, FormProvider, useWatch } from 'react-hook-form';
import {
  TClient,
  useUpdateApplicationMutation,
  clientApi,
  useGetApplicationByIdQuery,
} from '../../redux/services/client';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationFormChanged } from '../../redux/appSlice';
import { CustomRadioButton } from '../CustomRadioButton';
import { EditProviders } from '../EditProviders';
import {
  TOauthProvider,
  TMiscProvider,
  providerApi,
  useGetProvidersQuery,
  useDeactivateProvidersMutation,
  MiscProviderType,
  EGetProviderAction,
  OauthProviderType,
} from '../../redux/services/provider';
import { formatDate, isObjectEmpty } from '../../helpers';
import { EditApplicationHeader, TProviderItem } from './EditApplicationHeader';
import { EditApplicationFooter } from './EditApplicationFooter';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from '../../redux/store';
import {
  ProviderScope,
  useActivateLicenseMutation,
  useGetLicensesQuery,
  useGetRulesProvidersQuery,
} from '../../redux/services/settings';
import { setNotice } from '../../redux/noticesSlice';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { EGrantTypes } from './CreateApplication';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  settingsApi,
  TProfileField,
  TRule,
  useEditSettingsMutation,
  useGetProfileFieldsQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { ProfileField } from './ProfileField';
import { EditProfileField } from './EditProfileField';
import { EditRuleValidations } from './EditRuleValidations';
import { useGetUserQuery } from '../../redux/services/user';

export const editProfileSchema = (rules: TRule[]): yup.AnyObjectSchema => {
  if (!rules) return yup.object();
  return generateValidationSchema(rules.filter((rule) => rule.field_name !== 'password'));
};

export const generateValidationSchema = (rules: TRule[]) => {
  const schemaFields = rules.reduce((schema, field) => {
    if (!field.active) return schema;

    let fieldValidations = yup.string().nullable().notRequired();

    // Применение всех активных правил валидации для поля
    field.validations.forEach((validation) => {
      if (validation.active) {
        fieldValidations = fieldValidations.test({
          name: `${field.field_name}-regex`,
          message: validation.error,
          test: (value) => {
            if (field.required && (value === null || value === undefined || value === '')) {
              return false; // пропускаем проверку, если поле обязательное и пустое
            }
            // Применяем regex только если поле не пустое
            if (value === null || value === undefined || value === '') {
              return true; // пропускаем проверку, если поле пустое
            }
            const result = new RegExp(validation.regex).test(value);
            return result;
          },
        });
      }
    });

    return {
      ...schema,
      [field.field_name]: fieldValidations,
    };
  }, {});

  return yup.object().shape(schemaFields).required();
};

export enum RegistrationPolicyVariants {
  allowed = 'allowed',
  allowed_autoregistration_only = 'allowed_autoregistration_only',
  disabled = 'disabled',
}

export type TEditSettingsInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  avatar: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  widget_title: string;
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  phone_provider_enabled: boolean;
  phone_number_required: boolean;
};

const schema = yup
  .object({
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    registration_policy: yup.string().required('Обязательно поле'),
    allow_everyone_to_create_applications: yup.boolean().required('Обязательно поле'),
    authorize_only_admins: yup.boolean().required('Обязательно поле'),
    phone_number_required: yup.boolean(),
  })
  .required();

type TEditSettings = {
  selectedClient: TClient;
  userId: string;
};

export const EditSettings: FC<TEditSettings> = ({ selectedClient, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savedCallback = useRef<() => void>();
  const { openedFromMenu } = useParams<{ openedFromMenu: string }>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const { data: dataSettings } = useGetSettingsQuery();
  const { data: rules = [] } = useGetRulesProvidersQuery();
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: license, isFetching: licensesFetching } = useGetLicensesQuery();
  const { data: providers = [], isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
    action: EGetProviderAction.change,
  });
  const { data: client, isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: selectedClient.client_id || '' },
    {
      skip: !userId || !selectedClient.client_id,
    },
  );
  // #807
  // const { data: availableUsersCount } = useGetAvailableUsersCountQuery();

  const [activateLicense] = useActivateLicenseMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [editSettings, editSettingsResult] = useEditSettingsMutation();
  const [updateApplication, { isLoading: updateApplicationLoading }] =
    useUpdateApplicationMutation();

  const [inputLicense, setInputLicense] = useState('');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [addLicenseModalOpen, setAddLicenseModalOpen] = useState(false);
  const [savePasswordPolicyModalOpen, setSavePasswordPolicyModalOpen] = useState(false);
  const [claims, setClaims] = useState<{ gravatar: string[]; oauth: string[] }>({
    gravatar: [],
    oauth: [],
  });
  // TODO: для phoneProvider принимать и передавать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
  // TODO: для emailProvider тоже принимать только необходимые параметры
  const [phoneProvider, setPhoneProvider] = useState<TMiscProvider | undefined>(undefined);
  const [emailProvider, setEmailProvider] = useState<TMiscProvider | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<TProviderItem | undefined>(undefined);
  const [selectedProviderType, setSelectedProviderType] = useState<ProviderScope | undefined>(
    undefined,
  );
  const [selectedProfileField, setSelectedProfileField] = useState<TProfileField | undefined>(
    undefined,
  );
  const { refetch: getUser } = useGetUserQuery();

  const hasActiveLicense = !licensesFetching && license;
  const listLoginProviders: TProviderItem[] = [];
  const listTrustedProviders: TProviderItem[] = [];

  for (const provider of providers) {
    const rule = rules.find((rule) => rule.type === provider.type);
    if (!rule) {
      continue;
    }

    if (!rule.allowedScopes) {
      continue;
    }

    if (
      rule.allowedScopes.includes(ProviderScope.login) ||
      rule.allowedScopes.includes(ProviderScope.otp) ||
      rule.allowedScopes.includes(ProviderScope.trusted)
    ) {
      listLoginProviders.push({
        rule,
        provider,
      });
    }

    if (rule.allowedScopes.includes(ProviderScope.trusted)) {
      listTrustedProviders.push({
        rule,
        provider,
      });
    }
  }

  const generalProfileFields = profileFields?.filter((item) => item.type === 'general');
  const customProfileFields = profileFields?.filter((item) => item.type === 'custom');

  const listGeneralProfileFields = generalProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
      />
    );
  });
  const listCustomProfileFields = customProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
        deleted={true}
      />
    );
  });

  useEffect(() => {
    if (dataSettings)
      setClaims({
        oauth: dataSettings.default_public_profile_claims_oauth
          ? dataSettings.default_public_profile_claims_oauth.split(' ')
          : [],
        gravatar: dataSettings.default_public_profile_claims_gravatar
          ? dataSettings.default_public_profile_claims_gravatar.split(' ')
          : [],
      });
  }, [dataSettings]);

  useEffect(() => {
    setValue('default_public_profile_claims_oauth', claims.oauth.join(' '), { shouldDirty: true });
    setValue('default_public_profile_claims_gravatar', claims.gravatar.join(' '), {
      shouldDirty: true,
    });
  }, [claims]);

  useEffect(() => {
    if (updateApplicationLoading && editSettingsResult.isSuccess) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Настройки личного кабинета сохранены.`,
          timestamp: new Date().toString(),
        }),
      );
      navigate('/applications');
    }
  }, [updateApplicationLoading, editSettingsResult]);

  useEffect(() => {
    getUser();
  }, [profileFields]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
      setClaims({
        gravatar: [],
        oauth: [],
      });
    };
  }, []);

  useEffect(() => {
    if (providers.length) {
      if (selectedProfileField?.field === 'phone_number') {
        const phoneProvider = providers.find(
          (provider) => provider.type === MiscProviderType.PHONE,
        );

        if (phoneProvider && phoneProvider.type === MiscProviderType.PHONE)
          setPhoneProvider(phoneProvider);
      }

      if (selectedProfileField?.field === 'email') {
        const emailProvider = providers.find(
          (provider) => provider.type === MiscProviderType.EMAIL,
        );

        if (emailProvider && emailProvider.type === MiscProviderType.EMAIL)
          setEmailProvider(emailProvider);
      }
    }
  }, [selectedProfileField, providers]);

  const methods = useForm<TEditSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient.redirect_uris.map((uri) => ({ value: uri })) || [{ value: '' }],
      post_logout_redirect_uris: selectedClient.post_logout_redirect_uris.map((uri) => ({
        value: uri,
      })) || [{ value: '' }],
      avatar: selectedClient.avatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  const registration_policy = useWatch({ control, name: 'registration_policy' });
  const allow_everyone_to_create_applications = useWatch({
    control,
    name: 'allow_everyone_to_create_applications',
  });
  const authorize_only_admins = useWatch({ control, name: 'authorize_only_admins' });

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TEditSettingsInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;
    const payload = Object.entries(data).map(([key, value]) => {
      if (key === 'post_logout_redirect_uris' || key === 'redirect_uris') {
        return [
          key,
          data[key].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []),
        ];
      }

      return [key, value];
    });

    const isKeyOfGeneralSettings = (
      key:
        | string
        | number
        | boolean
        | string[]
        | File
        | {
            name: string;
            value: string;
          }[]
        | TWidgetColors
        | null,
    ) => {
      return (
        typeof key === 'string' &&
        [
          'registration_policy',
          'allow_everyone_to_create_applications',
          'authorize_only_admins',
          'default_public_profile_claims_oauth',
          'default_public_profile_claims_gravatar',
          'phone_provider_enabled',
        ].includes(key)
      );
    };

    await Promise.all([
      updateApplication({
        currentClientId: selectedClient.client_id,
        params: {
          ...Object.fromEntries(payload.filter(([key]) => !isKeyOfGeneralSettings(key))),
          grant_types: [
            EGrantTypes.authorization_code,
            EGrantTypes.refresh_token,
            EGrantTypes.device_flow,
          ],
          registration_access_token: selectedClient.registration_access_token?.jti,
          client_id: selectedClient.client_id,
          redirect_uris: selectedClient.redirect_uris,
          post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        },
      }),
      editSettings(Object.fromEntries(payload.filter(([key]) => isKeyOfGeneralSettings(key)))),
    ]);

    dispatch(providerApi.util.invalidateTags(['Providers']));

    dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const handleCancelLicense = () => {
    setInputLicense('');
    setAddLicenseModalOpen(false);
  };

  const handleSaveLicense = async () => {
    if (!inputLicense) return;
    activateLicense({ license: inputLicense });
    setInputLicense('');
    setAddLicenseModalOpen(false);
  };

  const handleProviderClick = (providerItem: TProviderItem, providerScope: ProviderScope) => {
    if (
      providerScope === ProviderScope.login &&
      !providerItem.rule.allowedScopes.includes(ProviderScope.login)
    ) {
      return;
    }

    if (
      providerItem.provider.type !== MiscProviderType.CREDENTIALS &&
      providerItem.provider.type !== MiscProviderType.EMAIL &&
      providerItem.provider.type !== MiscProviderType.QRCODE &&
      providerItem.provider.type !== MiscProviderType.PHONE &&
      providerItem.provider.client_id === selectedClient.client_id
    ) {
      setSelectedProvider(providerItem);
      setSelectedProviderType(providerScope);
      setProviderModalOpen(true);
    }
  };

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateApplicationLoading || getApplicationFetching || !selectedClient) return;
    const provider_id =
      ((provider.type || '') in OauthProviderType ? 'oauth_' : 'misc_') + provider.id;
    await updateApplication({
      currentClientId: selectedClient.client_id,
      params: {
        required_providers_ids: selectedClient.required_providers_ids.includes(provider_id)
          ? selectedClient.required_providers_ids.filter((id) => id !== provider_id)
          : [...selectedClient.required_providers_ids, provider_id],
        grant_types: selectedClient.grant_types,
        registration_access_token: selectedClient.registration_access_token?.jti,
        client_id: selectedClient.client_id,
        redirect_uris: selectedClient.redirect_uris,
        post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        require_signed_request_object: selectedClient.require_signed_request_object,
        request_uris: selectedClient.request_uris,
        id_token_signed_response_alg: selectedClient.id_token_signed_response_alg,
        response_types: selectedClient.response_types,
        introspection_endpoint_auth_method: selectedClient.introspection_endpoint_auth_method,
        require_auth_time: selectedClient.require_auth_time,
        revocation_endpoint_auth_method: selectedClient.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient.token_endpoint_auth_method,
        subject_type: selectedClient.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        {!openedFromMenu && (
          <>
            <Button
              onClick={() => {
                if (applicationFormChanged) {
                  savedCallback.current = () => navigate(-1);
                  return setSaveModalOpen(true);
                }
                navigate(-1);
              }}
              variant="custom3"
              className={clsx('text-15', styles['button-back'])}
              startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
            >
              Приложение
            </Button>
          </>
        )}
        {!!openedFromMenu && (
          <div className={styles['header-text']}>
            <Typography
              className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
            >
              Настройки личного кабинета
            </Typography>
            <Typography style={{ marginBottom: 14 }} className={clsx('text-14', 'color-858BA0')}>
              Добавляйте способы входа, управляйте доступом к личному кабинету и настраивайте
              внешний вид виджета для авторизации пользователей.
            </Typography>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditApplicationHeader
              coverSrc={coverSrc}
              setCoverSrc={setCoverSrc}
              avatarSrc={avatarSrc}
              client={selectedClient}
              setAvatarSrc={setAvatarSrc}
              openedFromMenu={openedFromMenu}
            />
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Способы входа
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  onClick={() => {
                    setSelectedProviderType(ProviderScope.login);
                    setProviderModalOpen(true);
                  }}
                >
                  Настроить
                </Button>
                <div className={styles.providers}>
                  {providersLoading &&
                    [null, null].map((_, index) => (
                      <div key={index} className={styles.provider}>
                        <IconWrapper>
                          <Skeleton width={20} height={30} />
                        </IconWrapper>
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            <Skeleton />
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            OAuth 2
                          </Typography>
                        </div>
                        <IconButton className={styles['icon-button-wrapper']}>
                          <CloseIcon className={styles['icon-button']} />
                        </IconButton>
                      </div>
                    ))}
                  {listLoginProviders
                    .filter((providerItem) => providerItem.provider.is_active)
                    .map((providerItem) => {
                      const required = selectedClient.required_providers_ids.includes(
                        ((providerItem.provider.type || '') in OauthProviderType
                          ? 'oauth_'
                          : 'misc_') + providerItem.provider.id,
                      );
                      return (
                        <div
                          key={providerItem.provider.id + providerItem.provider.type}
                          className={styles.provider}
                          onClick={() => handleProviderClick(providerItem, ProviderScope.login)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                BACKEND_URL + '/' + providerItem.provider.avatar
                              })`,
                            }}
                            className={styles['provider-icon-wrapper']}
                          >
                            {!providerItem.provider.avatar && <IdIcon />}
                          </div>
                          <div className={styles['provider-name-wrapper']}>
                            <Typography
                              className={clsx('text-14', 'color-0B1641', styles['provider-name'])}
                            >
                              {providerItem.provider.name}
                            </Typography>
                            <Typography className={clsx('text-12', 'color-858BA0')}>
                              {getProviderTitleByType(providerItem.provider.type)}
                            </Typography>
                          </div>
                          {providerItem.rule.requireable && (
                            <Tooltip
                              arrow
                              title={
                                <div>
                                  <Typography className="text-17">
                                    Сделать {required ? 'необязательным' : 'обязательным'}
                                  </Typography>
                                  <Typography className="text-12">
                                    Для входа в приложение {required ? 'не' : ''} требуется наличие
                                    способа входа в профиле пользователя
                                  </Typography>
                                </div>
                              }
                              classes={{
                                tooltip: styles['input-tooltip'],
                                arrow: styles['input-tooltip-arrow'],
                              }}
                            >
                              <IconButton
                                style={{ marginRight: 8 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleChangeRequiredProviders(providerItem.provider);
                                }}
                                className={styles['icon-button-wrapper']}
                              >
                                {selectedClient.required_providers_ids.includes(
                                  ((providerItem.provider.type || '') in OauthProviderType
                                    ? 'oauth_'
                                    : 'misc_') + providerItem.provider.id,
                                ) ? (
                                  <StarFilled
                                    fill="rgb(182, 186, 198)"
                                    width={20}
                                    height={20}
                                    className={styles['icon-button']}
                                  />
                                ) : (
                                  <Star
                                    fill="rgb(182, 186, 198)"
                                    width={20}
                                    height={20}
                                    className={styles['icon-button']}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                          {providerItem.provider.is_active &&
                            !(
                              selectedClient.client_id === CLIENT_ID &&
                              providerItem.provider.type === MiscProviderType.CREDENTIALS
                            ) && (
                              <IconButton
                                onClick={async (e) => {
                                  if (!userId) return;
                                  e.stopPropagation();
                                  await deactivateProvider({
                                    body: {
                                      providers: [
                                        {
                                          id: String(providerItem.provider.id),
                                          client_id: providerItem.provider.client_id,
                                        },
                                      ],
                                    },
                                    client_id: selectedClient.client_id,
                                  });
                                  dispatch(
                                    clientApi.endpoints.getApplicationById.initiate(
                                      { client_id: selectedClient.client_id, user_id: userId },
                                      {
                                        subscribe: false,
                                        forceRefetch: true,
                                      },
                                    ),
                                  );
                                }}
                                className={styles['icon-button-wrapper']}
                              >
                                <CloseIcon className={styles['icon-button']} />
                              </IconButton>
                            )}
                        </div>
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Доверенные провайдеры
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  onClick={() => {
                    setSelectedProviderType(ProviderScope.trusted);
                    setProviderModalOpen(true);
                  }}
                >
                  Настроить
                </Button>
                <div className={styles.providers}>
                  {providersLoading &&
                    [null, null].map((_, index) => (
                      <div key={index} className={styles.provider}>
                        <IconWrapper>
                          <Skeleton width={20} height={30} />
                        </IconWrapper>
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            <Skeleton />
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            OAuth 2
                          </Typography>
                        </div>
                        <IconButton className={styles['icon-button-wrapper']}>
                          <CloseIcon className={styles['icon-button']} />
                        </IconButton>
                      </div>
                    ))}
                  {listTrustedProviders.map((providerItem) => {
                    return (
                      <div
                        key={providerItem.provider.id + providerItem.provider.type}
                        className={styles.provider}
                        onClick={() => handleProviderClick(providerItem, ProviderScope.trusted)}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              BACKEND_URL + '/' + providerItem.provider.avatar
                            })`,
                          }}
                          className={styles['provider-icon-wrapper']}
                        >
                          {!providerItem.provider.avatar && <IdIcon />}
                        </div>
                        <div className={styles['provider-name-wrapper']}>
                          <Typography
                            className={clsx('text-14', 'color-0B1641', styles['provider-name'])}
                          >
                            {providerItem.provider.name}
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            {getProviderTitleByType(providerItem.provider.type)}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={styles['header-1']}>
                  Настройка профиля пользователя
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
                  Настройте параметры полей профиля и добавьте идентификаторы, которые должны быть у
                  пользователя в профиле
                </Typography>
                <Typography className={styles['header-2']}>Парольная политика</Typography>
                <ListItem
                  className={styles['password-item']}
                  onClick={() => setSavePasswordPolicyModalOpen(true)}
                >
                  <div className={styles['password-content']}>
                    <div className={styles['password-head']}>
                      <Typography className={styles['password-title']}>Пароль</Typography>
                      <Typography className={styles['password-field']}>password</Typography>
                    </div>
                  </div>
                </ListItem>
                <Typography className={styles['header-2']}>Основная информация</Typography>
                {listGeneralProfileFields}
                <Typography className={styles['header-2']}>Дополнительная информация</Typography>
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  startIcon={<AddIcon className={styles['add-icon']} />}
                  onClick={() => setProfileModalOpen(true)}
                >
                  Добавить
                </Button>
                {listCustomProfileFields}
              </AccordionDetails>
            </Accordion>
            {/* #807
            <Typography
              className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
            >
              Количество доступных мест:{' '}
              {Number.isFinite(availableUsersCount)
                ? Math.max(availableUsersCount as number, 0)
                : 'неограничено'}
            </Typography> */}
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Лицензия
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ alignItems: 'flex-start' }}
                className={styles['accordion-details']}
              >
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  onClick={() => setAddLicenseModalOpen(true)}
                >
                  {license ? 'Заменить лицензию' : 'Добавить лицензию'}
                </Button>
                {license && (
                  <div className={styles['license-content']}>
                    <div className={styles['license-head']}>
                      <Typography className={styles['license-title']}>
                        Лицензия {hasActiveLicense ? 'активна' : 'не активна'}
                      </Typography>
                      <Typography className={styles['license-description']}>
                        Срок действия:{' '}
                        {license.expirationTime === 0
                          ? 'бессрочная'
                          : formatDate(new Date(license.expirationTime * 1000))}
                      </Typography>
                      <Typography className={styles['license-description']}>
                        Ключи: {license.providers.join(', ')}
                      </Typography>
                    </div>
                    <div className={styles.licenseWrapper}>
                      <Tooltip
                        placement="bottom-end"
                        arrow
                        title={'Копировать лицензию'}
                        classes={{
                          tooltip: styles['input-tooltip'],
                          arrow: styles['input-tooltip-arrow'],
                        }}
                      >
                        <IconButton
                          onClick={async () => {
                            await navigator.clipboard.writeText(license.value);
                          }}
                          className={styles['license-button']}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Настройки доступа
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ alignItems: 'flex-start' }}
                className={styles['accordion-details']}
              >
                <div className={styles['input-wrapper']}>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Ограниченный доступ
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            Вход в личный кабинет доступен только для пользователей с правами
                            “Администратор” {PROJECT_NAME}
                          </Typography>
                        </div>
                      }
                      checked={authorize_only_admins}
                      onClick={() => {
                        setValue('authorize_only_admins', true, { shouldDirty: true });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Неограниченный доступ
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            Вход в личный кабинет не ограничен
                          </Typography>
                        </div>
                      }
                      checked={!authorize_only_admins}
                      onClick={() => {
                        setValue('authorize_only_admins', false, { shouldDirty: true });
                      }}
                    />
                  </div>
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Запрет регистрации
                  </Typography>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация запрещена
                          </Typography>
                        </div>
                      }
                      checked={registration_policy === RegistrationPolicyVariants.disabled}
                      onClick={() => {
                        setValue('registration_policy', RegistrationPolicyVariants.disabled, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация разрешена
                          </Typography>
                        </div>
                      }
                      checked={registration_policy === RegistrationPolicyVariants.allowed}
                      onClick={() => {
                        setValue('registration_policy', RegistrationPolicyVariants.allowed, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Разрешена только авторегистрация
                          </Typography>
                        </div>
                      }
                      checked={
                        registration_policy ===
                        RegistrationPolicyVariants.allowed_autoregistration_only
                      }
                      onClick={() => {
                        setValue(
                          'registration_policy',
                          RegistrationPolicyVariants.allowed_autoregistration_only,
                          { shouldDirty: true },
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Регистрация пользователей с правами “Администратора”
                  </Typography>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация с правами администратора
                          </Typography>
                        </div>
                      }
                      checked={allow_everyone_to_create_applications}
                      onClick={() => {
                        setValue('allow_everyone_to_create_applications', true, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация без прав администратора
                          </Typography>
                        </div>
                      }
                      checked={!allow_everyone_to_create_applications}
                      onClick={() => {
                        setValue('allow_everyone_to_create_applications', false, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <EditApplicationFooter
              coverSrc={coverSrc}
              applicationFormChanged={applicationFormChanged}
              avatarSrc={avatarSrc}
              savedCallback={savedCallback}
              setSaveModalOpen={setSaveModalOpen}
            />
          </form>
        </FormProvider>
        <EditProviders
          close={() => setProviderModalOpen(false)}
          clearSelectedProvider={() => {
            setSelectedProvider(undefined);
            setSelectedProviderType(undefined);
          }}
          isOpen={providerModalOpen}
          selectedProvider={selectedProvider}
          providerType={selectedProviderType}
        />
        <EditProfileField
          close={() => setProfileModalOpen(false)}
          clearSelectedProfileField={() => setSelectedProfileField(undefined)}
          isOpen={profileModalOpen}
          selectedProfile={selectedProfileField}
          claims={claims}
          phoneProvider={phoneProvider}
          emailProvider={emailProvider}
        />
        <EditRuleValidations
          close={() => setSavePasswordPolicyModalOpen(false)}
          field_name={'password'}
          isOpen={savePasswordPolicyModalOpen}
        />
        <Modal open={addLicenseModalOpen} onClose={handleCancelLicense}>
          <div
            className={styles['save-modal']}
            style={{ display: 'flex', flexDirection: 'column', padding: 20 }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
                Введите значение
              </Typography>
              <IconButton onClick={handleCancelLicense}>
                <CloseIcon />
              </IconButton>
            </div>
            <TextField
              value={inputLicense}
              onChange={(e) => setInputLicense(e.target.value)}
              fullWidth
              variant="standard"
              style={{ marginBottom: 20 }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="custom" color="secondary" onClick={handleCancelLicense}>
                Отмена
              </Button>
              <Button onClick={handleSaveLicense} variant="custom" style={{ marginLeft: 24 }}>
                Сохранить
              </Button>
            </div>
          </div>
        </Modal>
        <Modal open={saveModalOpen} onClose={closeSaveModal}>
          <div className={styles['save-modal']}>
            <div style={{ display: 'flex' }}>
              <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
                Сохранение изменений
              </Typography>
              <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
              Изменения не сохранены. Продолжить без сохранения?
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="custom" color="secondary" onClick={closeSaveModal}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  savedCallback.current?.();
                  dispatch(setApplicationFormChanged(false));
                  setSaveModalOpen(false);
                }}
                variant="custom"
                style={{ marginLeft: 24 }}
              >
                Продолжить
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
