import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  useCreateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty, isOwner } from '../helpers';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom-v5-compat';
import { RootState } from '../redux/rootReducer';
import { useSelector } from 'react-redux';
import { PasswordTextfield } from './custom/PasswordTextfield';

export type CreateKloudProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
  external_client_id: string;
  external_client_secret: string;
  issuer?: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .min(1, 'Обязательное поле')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .nullable(true)
    .transform((v) => (typeof v === 'undefined' ? null : v)),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateKloudProvider: FC<TCreateProvider> = ({
  isOpen,
  close,
  pathToAvatar,
  scope,
}) => {
  const methods = useForm<CreateKloudProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      password_required: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateKloudProviderInputs & { id: string; type: MiscProviderType.KLOUD }
      > = JSON.parse(text);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, avatar, id, ...restInputs } = provider || {};

      if (type !== MiscProviderType.KLOUD) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateKloudProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateKloudProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
    }
    createProvider({
      body: {
        type: MiscProviderType.KLOUD,
        ...rest,
        avatar: avatar ? avatar : null,
        is_active: true,
      },
      client_id: clientId,
    });
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      // TODO: BackdropProps поменять во всём проекте на slotProps
      // slotProps={{
      //   backdrop: { style: { background: 'none' } },
      // }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа KLOUD
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              pathToAvatar={pathToAvatar}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              setAvatarLink={setAvatarLink}
              setAvatarValue={setAvatarValue}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Идентификатор ресурса (client_id)
            </Typography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Уникальный идентификатор подключаемого ресурса
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Секретный ключ (client_secret)
            </Typography>
            <PasswordTextfield
              {...register('external_client_secret', {
                required: true,
                onChange: () => {
                  if (errors.external_client_secret) clearErrors('external_client_secret');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.external_client_secret}
              helperText={
                errors.external_client_secret ? errors.external_client_secret.message : ''
              }
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Секретный ключ ресурса
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Базовый адрес сервера авторизации (issuer)
            </Typography>
            <TextField
              {...register('issuer', {
                required: true,
                onChange: () => {
                  if (errors.issuer) clearErrors('issuer');
                },
              })}
              style={{ marginBottom: 32 }}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.issuer}
              helperText={errors.issuer ? errors.issuer.message : ''}
              fullWidth
              variant="standard"
            />
            <ProviderFooter type={MiscProviderType.KLOUD} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading}
              variant="custom"
              type="submit"
            >
              Создать
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для KLOUD-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={closeClipboardModal} variant="custom" style={{ marginLeft: 24 }}>
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
